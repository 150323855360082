@import "bootstrap";

.title {
  font-size: 18px;
  font-weight: 600;
}

.description {
  font-size: 16px;
  color: gray;
}

.alert-box {
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px;
  width: fit-content;
}

.card {
  padding: 20px;
}

.shadow {
  box-shadow: 0 0 25px rgba(0, 0, 0, .2);
}
